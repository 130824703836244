<template>
    <div class="basic_data_edit">
        <AssessHeader :currentStep="1" :dataSource="assessHeaderData"></AssessHeader>
        <div class="data_content data-content-form scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <div class="button_list">
                    <sn-button :snButton="snButton" @handleChange="handleChange"></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form class="demo-form-inline form_row" ref="formdata" :inline="true" :model="formdata.saveJson" size="small" label-width="200px" :rules="rules">
                <el-form-item label="对象类型" prop="PGDXLX">
                    <el-select v-model="formdata.saveJson.PGDXLX" placeholder="请选择对象类型" filterable :disabled="!isEdit" @change="handleDxlxChanged">
                        <el-option v-for="(item,index) in dxlxList" :key="index" :label="item.NAME" :value="item.CODE"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="遗产要素" prop="YCYSBM" v-if="formdata.saveJson.PGDXLX === '0' || formdata.saveJson.PGDXLX === '2'">
                    <el-select v-model="formdata.saveJson.YCYSBM" placeholder="请选择遗产要素" filterable clearable :disabled="!isEdit" @change="handleYcysChanged">
                        <el-option v-for="(item,index) in ycysList" :key="index" :label="item.MC" :value="item.BM"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="遗产要素" prop="YCYSBM" v-if="formdata.saveJson.PGDXLX === '2'">
                    <el-select v-model="formdata.saveJson.YCYSBM" placeholder="请选择遗产要素" filterable clearable :disabled="!isEdit" @change="handleYcysChanged">
                        <el-option v-for="(item,index) in ycysList" :key="index" :label="item.MC" :value="item.BM"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="遗产组成" prop="YCYSZC" v-if="formdata.saveJson.PGDXLX === '4'">
                    <el-select v-model="formdata.saveJson.YCYSZC" placeholder="请选择遗产组成" filterable clearable :disabled="!isEdit" @change="handleYczcChanged">
                        <el-option v-for="(item,index) in yczcList" :key="index" :label="item.YCZCMC" :value="item.YCZCMC"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="遗产要素" prop="" v-if="formdata.saveJson.PGDXLX !== '3' && formdata.saveJson.PGDXLX !== '0' && formdata.saveJson.PGDXLX !== '2' && formdata.saveJson.PGDXLX !== '4'">
                    <el-select v-model="formdata.saveJson.YCYSBM" placeholder="请选择遗产要素" filterable clearable :disabled="!isEdit" @change="handleYcysChanged">
                        <el-option v-for="(item,index) in ycysList" :key="index" :label="item.MC" :value="item.BM"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="对象名称" prop="" v-if="formdata.saveJson.PGDXLX === '1'">
                    <el-input v-model="formdata.saveJson.PGDXMC" placeholder="请选择包含对象" :maxlength="50" onkeydown="if(event.keyCode==32) return false" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="对象名称" prop="PGDXMC" v-if="formdata.saveJson.PGDXLX === '4'">
                    <el-input v-model="formdata.saveJson.PGDXMC" placeholder="请输入对象名称" :maxlength="50" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit"></el-input>
                </el-form-item>
                <el-form-item label="对象名称" prop="PGDXMC" v-if="formdata.saveJson.PGDXLX !== '1' && formdata.saveJson.PGDXLX !== '4'">
                    <el-input v-model="formdata.saveJson.PGDXMC" placeholder="请输入对象名称" :maxlength="50" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit"></el-input>
                </el-form-item>

                <el-form-item label="描述" prop="PGDXMS">
                    <el-input v-model="formdata.saveJson.PGDXMS" placeholder="请输入描述" :maxlength="50" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit"></el-input>
                </el-form-item>
                <div>
                    <el-form-item label="示意图">
                        <el-upload :action="`${uploadURL}UpLoad/FileSave?LJ=FXPG`"
                                accept="image/png,image/jpg,image/jpeg,image/gif"
                                list-type="picture-card"
                                :on-preview="handlePictureCardPreview"
                                :on-remove="handleRemove"
                                :file-list="fileList"
                                :limit="1"
                                :class="{ 'noneupload': fileList.length === 1 }"
                                :on-success="handleAvatarSuccess"
                                :disabled="!isEdit"
                        >
                            <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                    </el-form-item>
                </div>
                <div>
                    <el-form-item :class="formdata.saveJson.PGDXLX==='4'?'':'must-fill'" label="包含对象">
                    </el-form-item>
                </div>
                <div class="grid-content">
                    <CommonGrid :dataSource="monitorData" @handleSelectionChange="handleSelectionChange"></CommonGrid>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from "../edit-button.js";
import infoMixin from "../info_mixin.js";
import { mapActions } from "vuex";
import AssessHeader from './components/assess_header'
import CommonGrid from './components/common_grid'
export default {
    name: "assess_target_form",
    mixins: [infoMixin],
    data() {
        return {
            notNeedFile: true,
            snButton: snButton,
            formdata: {
                saveJson: {
                    PGDXLX: "",
                    YCYSBM: "",
                    YCYSZC: "",
                    PGDXMC: "",
                    PGDXMS: "",
                    CJRID: localStorage.userId,
                },
                heritageId: "",
                userName: "",
                userId: ""
            },
            dxlxList: [], // 对象类型
            ycysList: [], // 遗产要素
            yczcList: [], // 遗产组成

            rules: {
                PGDXLX: [
                    { required: true, message: '请选择对象类型', trigger: 'change' }
                ],
                YCYSBM: [
                    { required: true, message: '请选择遗产要素', trigger: 'change' }
                ],
                YCYSZC: [
                    { required: true, message: '请选择遗产组成', trigger: 'change' }
                ],
                PGDXMC: [
                    { required: true, message: '请输入对象名称', trigger: 'blur' },
                ]
            },
            fileList: [],
            uploadURL: window.REQUEST_URL,
            dialogImageUrl: "",
            dialogVisible: false,
            monitorData: {
                type: '',
                gridIndex: '0',
                title: '',
                Ycysbm: '',
                Ycyszc: '',
                dxlx: '',
                JcdxInfos: []
            },
            JcdxInfos: [],
            assessHeaderData: {
                title: '请创建评估对象',
                content: '请选择对象类型，录入对象名称等创建相关对象，若对已有评估对象进行评估，则不需要再创建评估对象，忽略此步即可！',
            }
        };
    },
    components: {
        AssessHeader,
        CommonGrid
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        'formdata.saveJson.PGDXSYT': function(val) {
            this.getPicList();
        },
        'formdata.saveJson.PGDXLX': function(val){
            if(val === '0'){
                this.getYcysList(val)
            }else if(val==='4') {
                this.getYcyszcList();
            } else{
                this.getYcysList('')
            }
        }
    },
    mounted() {
        this.snButton.buttonData[1].isShow = false;
        this.getDxlxList();
        this.getYcysList('');
        this.getYcyszcList('');
        if(this.type === '0'){
            this.monitorData = {
                type: this.type,
                gridIndex: '0',
                title: '',
                Ycysbm: '',
                Ycyszc: '',
                dxlx: '',
                JcdxInfos: []
            };
        }
    },
    methods: {
        ...mapActions([
            'AddPgdxInfo', //新建/修改评估对象
            "GetDxlxList", //获取对象类型列表
            "GetYcysListAA", //获取遗产要素列表
            "GetYcyszcList", //获取遗产组成列表
        ]),
        // 获取对象类型列表
        async getDxlxList() {
            this.dxlxList = [];
            let IsYcd = '';
            if(this.formdata.saveJson.PGDXLX === '3'){
                if(this.type !== '0'){
                    IsYcd = '1';
                }
            }
            let result = await this.GetDxlxList({
                IsYcd: IsYcd, //string 该评估对象为遗产地类型是此项传"1"，则评估对象类型不再进行遗产地过滤
            });
            this.dxlxList = result;
        },
        handleDxlxChanged(val){
            this.formdata.saveJson.YCYSBM = '';
            this.formdata.saveJson.PGDXMC = '';
            this.formdata.saveJson.YCYSZC = '';

            if(val === '0'){
                this.getYcysList(val)
            }else if(val==='4') {
                this.getYcyszcList();
            }else{
                this.getYcysList('')
            }

            this.setMonitorData();
        },
        // 获取遗产要素列表
        async getYcysList(val) {
            this.ycysList = [];
            let IncludeBm = '';
            if(this.formdata.saveJson.PGDXLX === '0'){
                if(this.type !== '0'){
                    IncludeBm = this.formdata.saveJson.YCYSBM
                }
            }
            let result = await this.GetYcysListAA({
                Lx: val, //string 类型，传0为筛选，传空为获取全部
                IncludeBm: IncludeBm, //string 类型 评估对象类型为遗产要素时，传所选的遗产要素编码，避免被过滤掉【同时上面Lx也要传0】
            });
            this.ycysList = result;
        },
        // 获取遗产组成列表
        async getYcyszcList(val) {
            this.yczcList = [];
            let IncludeBm = this.formdata.saveJson.YCYSZC;
            // if(this.formdata.saveJson.PGDXLX === '4'){
            //     if(this.type !== '0'){
            //         IncludeBm = this.formdata.saveJson.YCYSZC
            //     }
            // }
            let result = await this.GetYcyszcList({
                Lx: '0', //string 类型，传0为筛选，传空为获取全部
                IncludeBm: IncludeBm, //string 类型 评估对象类型为遗产要素时，传所选的遗产要素编码，避免被过滤掉【同时上面Lx也要传0】
            });
            this.yczcList = result;
        },
        // eslint-disable-next-line no-unused-vars
        handleYcysChanged(val){
            if(this.formdata.saveJson.PGDXLX==='1'){
                this.formdata.saveJson.PGDXMC = '';
            }
            this.setMonitorData();
        },
        // eslint-disable-next-line no-unused-vars
        handleYczcChanged(val){
            if(this.formdata.saveJson.PGDXLX==='4'){
                this.formdata.saveJson.PGDXMC = this.formdata.saveJson.YCYSZC;
            }
            this.setMonitorDataYczc();
        },
        setMonitorData(){
            this.monitorData = {
                type: this.type,
                gridIndex: '0',
                title: '',
                Ycysbm: this.formdata.saveJson.YCYSBM,
                Ycyszc: '',
                dxlx: this.formdata.saveJson.PGDXLX,
                JcdxInfos: this.formdata.saveJson.JCDXIDS
            }
        },
        setMonitorDataYczc(){
            this.monitorData = {
                type: this.type,
                gridIndex: '0',
                title: '',
                Ycysbm: '',
                Ycyszc: this.formdata.saveJson.YCYSZC,
                dxlx: this.formdata.saveJson.PGDXLX,
                JcdxInfos: this.formdata.saveJson.JCDXIDS
            }
        },
        getFromData() {
            this.fileList = [];
            if (this.$route.query.data) {
                Object.assign(this.formdata.saveJson, JSON.parse(this.$route.query.data));
                if(this.formdata.saveJson.PGDXSYT){
                    this.fileList.push({
                        PGDXSYTMC: this.formdata.saveJson.PGDXSYTMC,
                        PGDXSYT: this.formdata.saveJson.PGDXSYT,
                    })
                }
                
                this.getPicList();
                if(this.formdata.saveJson.PGDXLX === '4') {
                    this.monitorData = {
                        type: this.type,
                        gridIndex: '0',
                        title: '',
                        Ycysbm: '',
                        Ycyszc: this.formdata.saveJson.YCYSZC,
                        dxlx: this.formdata.saveJson.PGDXLX,
                        JcdxInfos: this.formdata.saveJson.JCDXIDS
                    };
                } else {
                    this.monitorData = {
                        type: this.type,
                        gridIndex: '0',
                        title: '',
                        Ycysbm: this.formdata.saveJson.YCYSBM,
                        Ycyszc: '',
                        dxlx: this.formdata.saveJson.PGDXLX,
                        JcdxInfos: this.formdata.saveJson.JCDXIDS
                    };
                }
            }
        },
        getPicList() {
            let pic = this.formdata.saveJson.PGDXSYT;
            if (pic) {
                this.fileList = [{ url: pic }];
            }
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        // eslint-disable-next-line no-unused-vars
        handleRemove(file, fileList) {
            this.fileList = [];
            this.formdata.saveJson.PGDXSYT = '';
            this.formdata.saveJson.PGDXSYTMC = '';
        },
        handleAvatarSuccess(res, file) {
            this.fileList.push(file);
            this.formdata.saveJson.PGDXSYTMC = res.ResultValue[0].FileName;
            this.formdata.saveJson.PGDXSYT = res.ResultValue[0].FilePath_ex;
        },
        handleSelectionChange(val){
            this.JcdxInfos = [];
            if(val && val.length>0){
                if(this.formdata.saveJson.PGDXLX === '1'){
                    this.formdata.saveJson.PGDXMC = val[0].DXMC;
                }

                val.forEach(item=>{
                    this.JcdxInfos.push({
                        Jcdxid: item.ID,
                        Jcdxbid: item.DXBID,
                        Ycysbm:item.YCYSBM,
                    })
                })
            }
        },
        async saveData() {
            if((this.formdata.saveJson.PGDXLX === '0' || this.formdata.saveJson.PGDXLX === '2') && !this.formdata.saveJson.YCYSBM){
                this.$message({
                    type: 'warning',
                    message: '请选择遗产要素'
                });
            }else if(this.formdata.saveJson.PGDXLX === '4' && !this.formdata.saveJson.YCYSZC) {
                this.$message({
                    type: 'warning',
                    message: '请选择遗产组成'
                });
            }else{
                if((this.JcdxInfos && this.JcdxInfos.length>0) || this.formdata.saveJson.PGDXLX==='4'){
                    let res = await this.AddPgdxInfo({
                        Info: JSON.stringify(this.formdata.saveJson),
                        JcdxInfos: JSON.stringify(this.JcdxInfos)
                    });
                    if (res.ISSUCCESS) {
                        this.$message({
                            type: 'success',
                            message: res.RESULTDESCRIPTION
                        });
                        this.returnList();
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.RESULTDESCRIPTION
                        });
                    }
                }else{
                    this.$message({
                        type: 'warning',
                        message: '请选择包含对象'
                    });
                }
            }
        },
    }
};
</script>
<style lang="scss" scoped>
.data-content-form {
    width: 100%;
    height: calc(100% - 150px)!important;
    .grid-content{
        width: 30%;
        margin-left: 200px;
        margin-top: -50px;
    }
}
</style>